import apiCall from "../core/apiCall";

export default {
    state: {
        sliders:[],
    },
    getters: {
        sliders: state => {
            return state.sliders;
        },
        main_sliders:state=>{
            return state.sliders.filter(item=>parseInt(item.type)===1);
        },
        news_sliders:state=>{
            return state.sliders.filter(item=>parseInt(item.type)===2);
        },
        boy_wing_sliders:state=>{
            return state.sliders.filter(item=>parseInt(item.type)===3);
        },
        girl_wing_sliders:state=>{
            return state.sliders.filter(item=>parseInt(item.type)===4);
        }
    },
    mutations: {
        SET_ALL_SLIDER(state, sliders) {
            state.sliders=sliders;
        },
    },
    actions: {
        async GET_ALL_SLIDER(context){
            context.commit('LOADER_STATUS_CHANGE',true);
            await apiCall.get('/slider/information')
                .then(res => {
                    context.commit('LOADER_STATUS_CHANGE',false);
                    context.commit('SET_ALL_SLIDER',res.data );
                    
                }).catch(()=>{
                    context.commit('LOADER_STATUS_CHANGE',false);
                })
        }
    },
}
