import apiCall from "../core/apiCall";

export default {
    state: {
        facility:[],
    },
    getters: {
        facility: state => {
            return state.facility;
        },
    },
    mutations: {
        SET_ALL_FACILITY(state, facility) {
            state.facility=facility;
        },
    },
    actions: {
        async GET_ALL_FACILITY(context){
            context.commit('LOADER_STATUS_CHANGE',true);
            await apiCall.get('/public/get/facility')
                .then(res => {
                    context.commit('LOADER_STATUS_CHANGE',false);
                    context.commit('SET_ALL_FACILITY',res.data );
                }).catch(()=>{
                    context.commit('LOADER_STATUS_CHANGE',false);
                })
        }
    },
}
