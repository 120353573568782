import apiCall from "../core/apiCall";

export default {
    state: {
        contact:[],
    },
    getters: {
        contact: state => {
            return state.contact;
        },
    },
    mutations: {
        SET_ALL_CONTACT(state, contact) {
            state.contact=contact;
        },
    },
    actions: {
        async GET_ALL_CONTACT(context){
            await apiCall.post('/contact/form/information/store')
                .then(res => {
                    context.commit('SET_ALL_CONTACT',res.data );
                })
        }
    },
}
