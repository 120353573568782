import apiCall from "../core/apiCall";

export default {
    state: {
        departments:[],
        genders:[],
        blood_groups:[],
        religions:[],
        designations:[],
        versions:[],
        shifts:[],
        wings:[],
        marital_status:[],
        marquees:[],
        glance: {},
    },
    getters: {
        marquees: state => {
            return state.marquees;
        },
        glance: state => {
            return state.glance;
        },
        departments: state => {
            return state.departments;
        },
        departmentByID: state=>id=>{
            let s=state.departments.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        },
        genders: state => {
            return state.genders;
        },
        genderByID: state=>id=>{
            let s=state.genders.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        },
        blood_groups: state => {
            return state.blood_groups;
        },
        blood_groupByID: state=>id=>{
            let s=state.blood_groups.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        },
        religions: state => {
            return state.religions;
        },
        religionByID: state=>id=>{
            let s=state.religions.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        },
        designations: state => {
            return state.designations;
        },
        designationByID: state=>id=>{
            let s=state.designations.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        },
        versions: state => {
            return state.versions;
        },
        versionByID: state=>id=>{
            let s=state.versions.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        },
        shifts: state => {
            return state.shifts;
        },
        shiftByID: state=>id=>{
            let s=state.shifts.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        },
        wings: state => {
            return state.wings;
        },
        wingByID: state=>id=>{
            let s=state.wings.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        },
        marital_status: state => {
            return state.marital_status;
        },
        marital_statusByID: state=>id=>{
            let s=state.marital_status.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        },
    },
    mutations: {
        SET_ALL_COMMON_INFO(state, info) {
            state.departments=info.departments;
            state.genders=info.genders;
            state.blood_groups=info.blood_groups;
            state.religions=info.religions;
            state.designations=info.designations;
            state.versions=info.versions;
            state.shifts=info.shifts;
            state.wings=info.wings;
            state.marital_status=info.marital_status;
        },
        SET_ALL_MARQUEE(state,marq){
            state.marquees=marq;
        },
        SET_ALL_GLANCE(state,glance){
            state.glance=glance;
        },
    },
    actions: {
        async GET_ALL_COMMON_INFO(context){
            context.commit('LOADER_STATUS_CHANGE',true);
            await apiCall.get('/public/get/all/v/s/w/r/d/ds/b/marital/status')
                .then(res => {
                    context.commit('LOADER_STATUS_CHANGE',false);
                    context.commit('SET_ALL_COMMON_INFO',res.data );
                }).catch(()=>{
                    context.commit('LOADER_STATUS_CHANGE',false);
                })
        },
        async GET_ALL_MARQUEE(context){
            context.commit('LOADER_STATUS_CHANGE',true);
            await apiCall.get('/public/get/all/marquees')
                .then(res => {
                    context.commit('LOADER_STATUS_CHANGE',false);
                    context.commit('SET_ALL_MARQUEE',res.data );
                }).catch(()=>{
                    context.commit('LOADER_STATUS_CHANGE',false);
                })
        },
        async GET_ALL_GLANCE(context){
            context.commit('LOADER_STATUS_CHANGE',true);
            await apiCall.get('/public/get/at/a/glances')
                .then(res => {
                    context.commit('LOADER_STATUS_CHANGE',false);
                    context.commit('SET_ALL_GLANCE',res.data );
                }).catch(()=>{
                    context.commit('LOADER_STATUS_CHANGE',false);
                })
        },
    },
}
