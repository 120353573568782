import Vue from 'vue'
import Vuex from 'vuex'

import slider from "./slider";
import home from "./home";
import contactinformation from "./contactinformation";
import contactform from "./contactform";
import menu from "./menu";
import subMenu from "./subMenu";
import singleEvent from "./singleEvent";
import employee from "./employee";
import common from "./common";
import facility from "./facility"
import footer from "./footer";
import socialLink from "./socialLink";

Vue.use(Vuex)
// Create a new store instance.
export default new Vuex.Store({
    state:{
        loader:false,
    },
    getters:{
        loader: state=>{ return state.loader;}
    },
    mutations:{
        LOADER_STATUS_CHANGE(state, status){
            state.loader= status;
        }
    },
    modules:{
        slider,menu,subMenu,home,
        contactinformation,contactform,singleEvent,
        employee,common,facility,footer,socialLink,
    }
});