import apiCall from "../core/apiCall";

export default {
    state: {
        employees:[],
    },
    getters: {
        employees: state => {
            return state.employees.filter(item=>item.id !== 1);
        },
        employeeByID:state =>id=>{
            return state.employees.find(item=>item.id===parseInt(id));
        },
        instituteEmployeeId: state=>id=>{
            let emp = state.employees.find(item=>item.id===parseInt(id));
            return emp?emp.institute_emp_id:'';
        },
    },
    mutations: {
        SET_ALL_EMPLOYEE(state, employees) {
            state.employees=employees;
        },
    },
    actions: {
        async GET_ALL_EMPLOYEE(context){
            context.commit('LOADER_STATUS_CHANGE',true);
            await apiCall.get('/public/get/all/faculties')
                .then(res => {
                    context.commit('SET_ALL_EMPLOYEE',res.data );
                    context.commit('LOADER_STATUS_CHANGE',false);
                }).catch(()=>{
                    context.commit('LOADER_STATUS_CHANGE',false);
                })
        }
    },
}
