import apiCall from "../core/apiCall";

export default {
    state: {
        social_links:[],
    },
    getters: {
        social_links: state => {
            return state.social_links;
        },
    },
    mutations: {
        SET_ALL_SOCIAL_LINK(state, social_links) {
            state.social_links=social_links;
        },
    },
    actions: {
        async GET_ALL_SOCIAL_LINK(context){
            context.commit('LOADER_STATUS_CHANGE',true);
            await apiCall.get('/public/get/social/links')
                .then(response => {
                    context.commit('SET_ALL_SOCIAL_LINK',response.data );
                    context.commit('LOADER_STATUS_CHANGE',false);
                }).catch(()=>{
                    context.commit('LOADER_STATUS_CHANGE',false);
                })
        }
    },
}
