import apiCall from "../core/apiCall";

export default {
    state: {
        contactinformation:[],
    },
    getters: {
        contactinformation: state => {
            return state.contactinformation;
        },
    },
    mutations: {
        SET_ALL_CONTACTINFORMATION(state, contactinformation) {
            state.contactinformation=contactinformation;
        },
    },
    actions: {
        async GET_ALL_CONTACTINFORMATION(context){
            context.commit('LOADER_STATUS_CHANGE',true);
            await apiCall.get('/contact/information')
                .then(res => {
                    context.commit('LOADER_STATUS_CHANGE',false);
                    context.commit('SET_ALL_CONTACTINFORMATION',res.data );
                }).catch(()=>{
                    context.commit('LOADER_STATUS_CHANGE',false);
                })
        }
    },
}
