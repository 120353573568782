import apiCall from "../core/apiCall";

export default {
    state: {
        single_events: {},
    },
    getters: {
        single_events: state => {
            return state.single_events;
        },
    },
    mutations: {
        SET_SINGLE_EVENT(state, single_events) {
            state.single_events=single_events;
        },
    },
    actions: {
        async GET_SINGLE_EVENT(context){
            context.commit('LOADER_STATUS_CHANGE',true);
            await apiCall.get('/home/page/information')
                .then(res => {
                    context.commit('LOADER_STATUS_CHANGE',false);
                    context.commit('SET_SINGLE_EVENT',res.data );
                }).catch(()=>{
                    context.commit('LOADER_STATUS_CHANGE',false);
                })
        }
    },
}
