import apiCall from "../core/apiCall";

export default {
    state: {
        footers:[],
        logo_name:{},
    },
    getters: {
        footers: state => {
            return state.footers;
        },
        footerByType:state=>type=>{
            return state.footers.filter(item=>item.type===type);
        },
        footerByID:state=>id=>{
            return state.footers.find(item=>item.id===parseInt(id));
        },
        logo_name: state => {
            return state.logo_name;
        },
    },
    mutations: {
        SET_ALL_FOOTER(state, footers) {
            state.footers=footers;
        },
        SET_ALL_LOGO_NAME(state, logo_name) {
            state.logo_name=logo_name;
        },
    },
    actions: {
        async GET_ALL_FOOTER(context){
            context.commit('LOADER_STATUS_CHANGE',true);
            await apiCall.get('/public/get/footers')
                .then(response => {
                    context.commit('LOADER_STATUS_CHANGE',false);
                    context.commit('SET_ALL_FOOTER',response.data );
                }).catch(()=>{
                    context.commit('LOADER_STATUS_CHANGE',false);
                })
        },
        async GET_LOGO_AND_NAME(context){
            context.commit('LOADER_STATUS_CHANGE',true);
            await apiCall.get('/public/site/logo/and/name')
                .then(response => {
                    context.commit('LOADER_STATUS_CHANGE',false);
                    context.commit('SET_ALL_LOGO_NAME',response.data );
                }).catch(()=>{
                    context.commit('LOADER_STATUS_CHANGE',false);
                })
        }
    },
}
