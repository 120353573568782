import apiCall from "../core/apiCall";

export default {
    state: {
        home:[],
    },
    getters: {
        home: state => {
            return state.home;
        },
    },
    mutations: {
        SET_ALL_HOME(state, home) {
            state.home=home;
        },
    },
    actions: {
        async GET_ALL_HOME(context){
            context.commit('LOADER_STATUS_CHANGE',true);
            await apiCall.get('/home/page/information')
                .then(res => {
                    context.commit('LOADER_STATUS_CHANGE',false);
                    context.commit('SET_ALL_HOME',res.data );
                }).catch(()=>{
                    context.commit('LOADER_STATUS_CHANGE',false);
                })
        }
    },
}
