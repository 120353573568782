import apiCall from "../core/apiCall";

export default {
    state: {
        sub_menus:[],
    },
    getters: {
        sub_menus: state => {
            return state.sub_menus;
        },
        single_sub_menus:state=>slug=>{
            return state.sub_menus.find(item => item.slug === slug);
        }
    },
    mutations: {
        SET_ALL_SUB_MENU_WITH_DATA(state, sub_menus) {
            state.sub_menus=sub_menus;
        },
    },
    actions: {
        async GET_ALL_SUB_MENU_WITH_DATA(context){
            context.commit('LOADER_STATUS_CHANGE',true);
            await apiCall.get('/public/site/sub_menu/with/data')
                .then((response) => {
                    context.commit('LOADER_STATUS_CHANGE',false);
                    context.commit('SET_ALL_SUB_MENU_WITH_DATA',response.data );
                }).catch(()=>{
                    context.commit('LOADER_STATUS_CHANGE',false);
                })
        }
    },
}
